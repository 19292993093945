import gql from 'graphql-tag';

export const ratesLogQueries =
gql`query RatesList($input: String) {
    ratesLog(input: $input) {
        error
        clientName
        rates {
            employee {
                email
                firstName
                lastName
            }
            rateType
            rateValue
            rateHours
            rateExtra
            rateCreated
            rateAction
            created
        }
    }
}`

export class RatesEmploeeInfo {
    email: string;
    firstName: string;
    lastName: string;
}

export class RatesLogInfo {
    id: string;
    employee: RatesEmploeeInfo
    rateType: string;
    rateValue: number;
    rateHours: number;
    rateExtra: number;
    rateCreated: string;
    rateAction: string;
    created: string;
}

export class ratesLogQuery {
    ratesLog: {
        error: string;
        clientName: string;
        rates: RatesLogInfo[];
    }
}