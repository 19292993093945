import gql from 'graphql-tag';

export const colleaguesQueries =
gql`query {
    colleagues {
        error
        colleagues {
            id
            email
            firstName
            lastName
            disabled
            lastLogin
        }
    }
}`

export const colleagueStatus =
gql`mutation ColleagueStatus($input: String) {
    colleagueStatus(input: $input) {
        error
    }
  }
`

export const colleagueDelete =
gql`mutation ColleagueDelete($input: String) {
    colleagueDelete(input: $input) {
        error
    }
  }
`

export const colleagueCreateEdit =
gql`mutation ColleagueCreateEdit($input: CreateEditColleagueInput) {
    colleagueCreateEdit(input: $input) {
        error
        colleague {
            id
            email
            firstName
            lastName
            disabled
            lastLogin
        }
    }
  }
`

export class Colleague {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    disabled: string;
    lastLogin: Date;
}

export class colleagueDeleteMut {
    colleagueDelete: {
        error: string;
    }
}

export class colleagueStatusMut {
    colleagueStatus: {
        error: string;
    }
}

export class colleagueEditCreateMut {
    colleagueCreateEdit: {
        error: string;
        colleague: Colleague
    }
}

export class colleaguesQuery {
    colleagues: {
        error: string;
        colleagues: Colleague[];
    }
}