import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { dashboardQueries, dashboardQuery, lastJobsModel, lastEmployeesModel, clientsModel, casesModel, employeeModel } from '../../queries/dashboard.queries';

@Component({
    selector: 'dashboard',
    templateUrl: './dashboard.component.html'
})

export class DashboardComponent {

    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    lastJobs: lastJobsModel[] = null;
    lastEmployees: lastEmployeesModel[] = null;
    clients: clientsModel = null;
    cases: casesModel = null;
    employee: employeeModel = null;

    constructor(private _ts: TranslateService, private _ap: Apollo, private _al: AlertService) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        let query = this._ap.watchQuery({ query: dashboardQueries, variables: {} });
        query.valueChanges.subscribe(result => {
            if(!(<dashboardQuery>result.data).dashboard) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                const resp = (<dashboardQuery>result.data).dashboard;
                if (resp.error) {
                    this._al.error("SERVER.AUTH_ERROR");
                } else {
                    this.lastJobs = resp.lastJobs;
                    this.lastEmployees = resp.lastEmployees;
                    this.clients = resp.clients;
                    this.cases = resp.cases;
                    this.employee = resp.employee;
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

}
