import gql from 'graphql-tag';

export const permissionsQueries =
gql`query Permissions($input: String) {
    permissions(input: $input) {
        error
        firstName
        lastName
        email
        allowedIp
        allowedClient
        permissions {
            employee { 
                edit
                report
                permissions
            }
            jobs_other_employee {
                edit
                delete
                view
            }
            clients {
                edit
                delete
                report
            }
            dashboard {
                clients
                cases
                employee
            }
        }
        clientsList {
            id
            name
        }
    }
}`

export const permissionsSave =
gql`mutation PermissionsSave($input: PermissionInput) {
    permissionsSave(input: $input) {
        error
    }
  }
`
class Client {
    id: string;
    name: string;
}

export class permissionsQuery {
    permissions: {
        error: string;
        firstName: string;
        lastName: string;
        email: string;
        allowedIp: string[];
        allowedClient: string[]
        clientsList: Client[]
        permissions: {
            employee: { 
                edit: boolean;
                report: boolean;
                permissions: boolean;
            }
            jobs_other_employee: {
                edit: boolean;
                delete: boolean;
                view: boolean;
            }
            clients: {
                edit: boolean;
                delete: boolean;
                report: boolean;
            }
            dashboard: {
                clients: boolean;
                cases: boolean;
                employee: boolean;
            }
        }
    }
}

export class permissionsSaveMut {
    permissionsSave: {
        error: string;
    }
}
