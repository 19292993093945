import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class FilesService {

    private subject = new Subject<any>();

    constructor() {
    }

    show() {
        this.subject.next({ show: true });
    }

    close() {
        this.subject.next({ show: false });
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    
}
