import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { forgotQueries, forgotQuery } from '../../queries/autentificate.queries';

@Component({
  selector: 'forgot',
  templateUrl: './forgot.component.html'
})

export class ForgotComponent {

  model: any = {email: '', office: '', offices: []};
  submitDisabled: boolean = false;
  showOffices: boolean = false;
  credentialError: boolean = false;

	constructor(private _ts: TranslateService, private _al: AlertService, private _ap: Apollo) {
    localStorage.removeItem('bearer');
    localStorage.removeItem('permissions');
    localStorage.removeItem('currentUser');
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    _ts.addLangs([lang]);
    _ts.setDefaultLang(lang);
	}

  submit() {
    this._al.close();
    this.submitDisabled = true;
    this.credentialError = false;
    let query = this._ap.watchQuery({ query: forgotQueries, variables: { input: { email: this.model.email, office: this.model.office } } });
    query.valueChanges.subscribe(result => {
      this.submitDisabled = false;
      let res = (<forgotQuery>result.data).forgot;
      if (res.error) {
        this.credentialError = true;
        this._al.error(res.error);
      } else if (res.offices) {
        this.showOffices = true;
        this.model.offices = res.offices;
      } else if (res.message) {
        this.model = {email: '', office: '', offices: []};
        this.showOffices = false;
        this._al.success(res.message);
      }
    }, err => {
      this.submitDisabled = false;
    });
  }
}
