import gql from 'graphql-tag';

export const reportClientQueries =
gql`query ReportsClient($input: reportsInput) {
    reportsClient(input: $input) {
        error
        client {
            name
        }
        cases {
            id
            name
        }
        employee {
           id
            firstName
            lastName
            email
        }
        works {
            workTime
            extraHours
            rateType
            addCost
            addCostDesc
            rateValue
            rateHours
            rateExtra
            description
            unbillable
            created
            employee {
                email
                firstName
                lastName
            }
            case {
                name
            }
        }
    }
}`

export const reportClientWorksQueries =
gql`query ReportsClient($input: reportsInput) {
    reportsClient(input: $input) {
        error
        cases {
            id
            name
        }
        works {
            workTime
            extraHours
            rateType
            addCost
            addCostDesc
            rateValue
            rateHours
            rateExtra
            description
            unbillable
            created
            employee {
                email
                firstName
                lastName
            }
            case {
                name
            }
        }
    }
}`

export class EmployeeInfo {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export class ClientInfo {
    name: string;
}

export class CaseInfo {
    id: string;
    name: string;
}

class WorkInfoEmployee {
    firstName: string;
    lastName: string;
    email: string;
}

class WorkInfoCase {
    name: string;
}

export class WorkInfo {
    workTime: number;
    extraHours: number;
    addCost: number;
    addCostDesc: string;
    rateType: string;
    rateValue: number;
    rateHours: number;
    rateExtra: number;
    description: string;
    unbillable: string;
    created: Date;
    employee: WorkInfoEmployee;
    case: WorkInfoCase;
}

export class ReportClientQueries {
    reportsClient: {
        error: string;
        client: ClientInfo;
        employee: EmployeeInfo[];
        cases: CaseInfo[];
        works: WorkInfo[]
    }
}