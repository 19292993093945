import gql from 'graphql-tag';

export const dashboardQueries =
gql`query dashboard {
    dashboard {
        error
        lastJobs {
            case_description
            client_name
            description
            created
        }
        lastEmployees {
            email
            firstName
            lastName
            lastLogin
        }
        clients {
            active
            inInvoice
            outInvoice
            total
        }
        cases {
            active
            open
            closed
            total
        }
        employee {
            active
            enabled
            disabled
            total
        }
    }
}`

export class lastJobsModel {
    work_description: String;
    case_description: String;
    client_name: String;
    created: Date;
}

export class lastEmployeesModel {
    email: String;
    firstName: String;
    lastName: String;
    lastLogin: Date;
}

export class clientsModel {
    active: Number;
    inInvoice: Number;
    outInvoice: Number;
    total: Number;
}

export class casesModel {
    active: Number;
    open: Number;
    closed: Number;
    total: Number;
}

export class employeeModel {
    active: Number;
    enabled: Number;
    disabled: Number;
    total: Number;
}

export class dashboardQuery {
    dashboard: {
        error: String;
        lastJobs: lastJobsModel[],
        lastEmployees: lastEmployeesModel[],
        clients: clientsModel,
        cases: casesModel,
        employee: employeeModel
    }
}