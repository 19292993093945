import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { resetQueries, resetQuery } from '../../queries/autentificate.queries';

@Component({
  selector: 'reset',
  templateUrl: './reset.component.html'
})

export class ResetComponent {

  model: any = {password: '', rpassword: '', resetID: ''};
  submitDisabled: boolean = false;
  credentialError: boolean = false;

	constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _al: AlertService, private _ap: Apollo) {
    localStorage.removeItem('bearer');
    localStorage.removeItem('permissions');
    localStorage.removeItem('currentUser');
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    _ts.addLangs([lang]);
    _ts.setDefaultLang(lang);
	}
 
  ngOnInit() {
    this._ar.params.subscribe(params => {
      if (params.id)
        this.model.resetID = params.id;
    });
  }

  submit() {
    this._al.close();
    this.credentialError = false;
    if ((this.model.password != '')&&(this.model.password == this.model.rpassword)) {
      this.submitDisabled = true;
      let query = this._ap.watchQuery({ query: resetQueries, variables: { input: { password: this.model.password, resetID: this.model.resetID } } });
      query.valueChanges.subscribe(result => {
        this.submitDisabled = false;
        let res = (<resetQuery>result.data).reset;
        if (res.error) {
          this.credentialError = true;
          this._al.error(res.error);
        } else if (res.message) {
          this._rtr.navigate(['/login']);
        }
      }, err => {
        this.submitDisabled = false;
      });
    } else {
      this.credentialError = true;
      this._al.error('RESET.MESSAGE.ERROR_PASSWORD');
    }
  }

}
