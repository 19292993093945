import gql from 'graphql-tag';

export const jobsQueries =
gql`query WorksList($input: worksListInput){
    works(input: $input) {
        error
        fullList {
            totalRecords
            fullList
        }
        clientName
        cases {
            id
            name
            statusOC
        }
        works {
            id
            case {
                id
                name
            }
            employee {
                id
                email
                firstName
                lastName
            }
            workTime
            addCost
            addCostDesc
            description
            unbillable
            created
        }
    }
}`

export const jobsCreateEdit =
gql`mutation WorkCreateEdit($input: CreateEditWorkInput) {
    workCreateEdit(input: $input) {
        error
        work {
            id
            case {
                id
                name
            }
            employee {
                id
                email
                firstName
                lastName
            }
            workTime
            addCost
            addCostDesc
            description
            unbillable
            created
        }
    }
  }
`

export const jobsDelete =
gql`mutation WorkDelete($input: String) {
    workDelete(input: $input) {
        error
    }
}`

export class CasesInfo {
    id: string;
    name: string;
    status: string;
}

export class CaseInfo {
    id: string;
    name: string;
}

export class EmployeeInfo {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
}

export class FullList {
    fullList: boolean;
    totalRecords: number;
}

export class JobsInfo {
    id: string;
    case: CaseInfo;
    employee: EmployeeInfo;
    workTime: number;
    addCost: number;
    addCostDesc: string;
    description: string;
    unbillable: string;
    created: Date;
}

export class jobsQuery {
    works: {
        error: string;
        clientName: string;
        fullList: FullList;
        cases: CasesInfo[];
        works: JobsInfo[];
    }
}

export class jobsCreateEditMut {
    workCreateEdit: {
        error: string;
        work: JobsInfo;
    }
}

export class jobsDeleteMut {
    workDelete: {
        error: string;
    }
}
