import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { colleaguesQueries, colleagueStatus, colleagueDelete, colleagueCreateEdit, colleaguesQuery, colleagueStatusMut, colleagueDeleteMut, colleagueEditCreateMut, Colleague } from '../../queries/colleagues.queries';

@Component({
    selector: 'colleagues',
    templateUrl: './colleagues.component.html'
})

export class ColleaguesComponent {

    permissions: any = {}; 
    currentUser: any = {};
    model: any = {};
    modelError: any = {emailError: false, passwordError: false};
    collapse: boolean = true;
    workers: Colleague[];
    buttonsDisabled: boolean = false;

    constructor(private _ts: TranslateService, private _rtr: Router, private _al: AlertService, private _ap: Apollo) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  
    ngOnInit() {
        this._al.close();
        let query = this._ap.watchQuery({ query: colleaguesQueries, variables: {} });
        query.valueChanges.subscribe(result => {
            if (!(<colleaguesQuery>result.data).colleagues) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                const resp = (<colleaguesQuery>result.data).colleagues;
                if (resp.error) {
                    this._al.error("SERVER.AUTH_ERROR");
                } else {
                    this.workers = resp.colleagues;
                    this.workers = this.workers.sort(function(a,b) { if ((a.firstName == null)) { return -1; } if(a.firstName > b.firstName) { return 1; } if(a.firstName < b.firstName) { return -1; } return 0; });
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    changeFormToCreate() {
        this._al.close();
        this.modelError = {emailError: false, passwordError: false};
        this.model = {
            form: "create", 
            id: null,
            email: '',
            password: '',
            firstName: '',
            lastName: ''
        };
    }

    changeFormToEdit(worker: Colleague) {
        this._al.close();
        this.modelError = {emailError: false, passwordError: false};
        this.model = {
            form: "edit", 
            id: worker.id,
            email: worker.email,
            password: '',
            firstName: worker.firstName,
            lastName: worker.lastName
        };
        window.scrollTo(0, 1);
    }

    changeFormToCalcel() {
        this._al.close();
        this.modelError = {emailError: false, passwordError: false};
        this.model = {};
    }

    changeStatus(worker: Colleague) {
        if (this.currentUser.id != worker.id) {
            this._al.close();
            let query = this._ap.mutate({ mutation: colleagueStatus, variables: { input: worker.id.toString() } });
            query.subscribe(result => {
                let res = (<colleagueStatusMut>result.data).colleagueStatus
                if (res.error == 'none')
                    worker.disabled = worker.disabled == 'No' ? 'Yes' : 'No';
                else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    deleteWorker(worker: Colleague) {
        if (this.currentUser.id != worker.id) {
            this._al.close();
            let query = this._ap.mutate({ mutation: colleagueDelete, variables: { input: worker.id.toString() } });
            query.subscribe(result => { 
                let res = (<colleagueDeleteMut>result.data).colleagueDelete
                if (res.error == 'none') {
                    let index = this.workers.indexOf(worker);
                    if (index !== -1)
                        this.workers.splice(index, 1);
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    saveForm() {
        this._al.close();
        this.buttonsDisabled = true
        this.modelError = {emailError: false, passwordError: false};
        if (this.model.email == '') {
            this.modelError.emailError = true;
        } else if ((this.model.password == '')&&(this.model.form == 'create')) {
            this.modelError.passwordError = true;
        } else {
            let query = this._ap.mutate({ mutation: colleagueCreateEdit, variables: { input: { "form": this.model.form, "id": this.model.id, "email": this.model.email, "password": this.model.password, "firstName": this.model.firstName, "lastName": this.model.lastName} } });
            query.subscribe(result => {
                this.buttonsDisabled = false
                let res = (<colleagueEditCreateMut>result.data).colleagueCreateEdit
                if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else if (res.error == 'E-mail in use') {
                    this._al.error('COLLEAGUES.ERROR.EMAIL_IN_USE');
                    window.scrollTo(0, 1);
                } else if (res.error == 'E-mail required') {
                    this.modelError.emailError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Password required') {
                    this.modelError.passwordError = true;
                    window.scrollTo(0, 1);
                } else if (res.colleague) {
                    let newUser = <Colleague>res.colleague;
                    if (this.model.form == 'create') {
                        this._al.success("COLLEAGUES.MESSAGES.CREATED");
                    } else {
                        let index = this.workers.findIndex(x => x.id === newUser.id);
                        if (index !== -1)
                            this.workers.splice(index, 1);
                        this._al.success("COLLEAGUES.MESSAGES.SAVED");
                    }
                    this.workers.unshift(newUser)
                    this.workers.sort(function(a,b) { if ((a.firstName == null)) { return -1; } if(a.firstName < b.firstName) { return -1; } if(a.firstName > b.firstName) { return 1; } return 0; });
                    this.modelError = {emailError: false, passwordError: false};
                    this.model = {};
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

}
