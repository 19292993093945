import gql from 'graphql-tag';

export const casesQueries =
gql`query CasesList($input: String){
    cases(input: $input) {
        error
        clientName
        cases {
            id
            name
            description
            statusOC
        }
    }
}`

export const caseCreateEdit =
gql`mutation CaseCreateEdit($input: CreateEditCaseInput) {
    caseCreateEdit(input: $input) {
        error
        case {
            id
            name
            description
            statusOC
        }
    }
}`

export const caseDelete =
gql`mutation CaseDelete($input: String) {
    caseDelete(input: $input) {
        error
    }
}`

export const caseStatus =
gql`mutation CaseStatus($input: String) {
    caseStatus(input: $input) {
        error
    }
}`

export class CaseInfo {
    id: string;
    name: string;
    description: string;
    statusOC: string
}

export class casesQuery {
    cases: {
        error: string;
        clientName: string;
        cases: CaseInfo[];
    }
}

export class caseCaseEditMut {
    caseCreateEdit: {
        error: string;
        case: CaseInfo;
    }
}

export class caseStatusMut {
    caseStatus: {
        error: string;
    }
}

export class caseDeleteMut {
    caseDelete: {
        error: string;
    }
}
