import { Directive } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
    selector: '[fillZero]',
    host: {
      '(blur)': 'onBlur($event)'
    }
})
  
export class FillZeroDirective {

    control: NgControl;

    constructor (control: NgControl) {
        this.control = control
    }

    onBlur () {
        let val = this.control.control.value;
        if (this.control.name == 'in_number')
            val = ('000' + val).slice(-3);
        if (this.control.name == 'in_month')
            val = ('00' + val).slice(-2);
        if (this.control.name == 'in_year')
            val = ('0000' + val).slice(-4);
        this.control.valueAccessor.writeValue(val)
        this.control.viewToModelUpdate(val)
    }
    
}
  