import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GraphQLModule } from './graphql.module';

import { TruncatePipe } from "./pipe/truncate.pipe";

import { AuthGuard } from "./guards/auth.guard";
import { AlertService } from "./services/alert.service";
import { AlertComponent } from "./components/Alert/alert.component";
import { FilesService } from "./services/files.service";
import { FilesComponent } from "./components/Alert/files.component";
import { FillZeroDirective } from "./services/fillZero.serve"
import { NavButtonsComponent } from "./components/NavButtons/nav.component";

import { LoginComponent } from './components/Guest/login.component';
import { ForgotComponent } from './components/Guest/forgot.component';
import { ResetComponent } from './components/Guest/reset.component';
import { DashboardComponent } from './components/Dashboard/dashboard.component';
import { ClientsComponent } from './components/Clients/clients.component';
import { RatesComponent } from './components/Rates/rates.component';
import { RatesLogComponent } from './components/Rates/ratesLog.component';
import { JobsComponent } from './components/Jobs/jobs.component';
import { CasesComponent } from './components/Cases/cases.component';
import { ColleaguesComponent } from './components/Colleagues/colleagues.component';
import { PermissionsComponent } from './components/Colleagues/permissions.component';
import { ClientReportComponent } from './components/Reports/clientReport.component';
import { ColleagueReportComponent } from './components/Reports/colleagueReport.component';
import { InvoiceComponent } from './components/Invoice/invoice.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent, TruncatePipe, FillZeroDirective, AlertComponent, FilesComponent, NavButtonsComponent,
    LoginComponent, ForgotComponent, ResetComponent,
    DashboardComponent, InvoiceComponent,
    ClientsComponent, CasesComponent, RatesComponent, RatesLogComponent, JobsComponent, 
    ColleaguesComponent, PermissionsComponent, 
    ClientReportComponent, ColleagueReportComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    AmplifyAngularModule,
    NgbModule,
    HttpClientModule, 
    GraphQLModule,
    TranslateModule.forRoot({ loader: { provide: TranslateLoader, useFactory: (createTranslateLoader), deps: [HttpClient] } }), 
  ],
  providers: [
    AlertService, FilesService, AuthGuard, AmplifyService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
