import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';

import { FilesService } from "../../services/files.service";

@Component({
  selector: 'files',
  templateUrl: 'files.component.html'
})

export class FilesComponent implements OnDestroy {
  private subscription: Subscription;
  toastShow: boolean = false;

  constructor(private _ts: TranslateService, private filesService: FilesService) {
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    _ts.addLangs([lang]);
    _ts.setDefaultLang(lang);
    this.subscription = filesService.getMessage().subscribe(toastShow => {
      this.toastShow = toastShow.show;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
