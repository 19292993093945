import gql from 'graphql-tag';

export const reportEmployeeQueries =
gql`query ReportsEmployee($input: reportsInput) {
    reportsEmployee(input: $input) {
        error
        employee {
            firstName
            lastName
            email
        }
        clients {
            id
            name
        }
        cases {
            id
            name
        }
        works {
            workTime
            extraHours
            rateType
            addCost
            addCostDesc
            rateValue
            rateHours
            rateExtra
            description
            unbillable
            created
            client {
                name
            }
            case {
                name
            }
        }
    }
}`

export const reportEmployeeCasesQueries =
gql`query ReportsEmployee($input: reportsInput) {
    reportsEmployee(input: $input) {
        error
        cases {
            id
            name
        }
        works {
            workTime
            extraHours
            rateType
            addCost
            addCostDesc
            rateValue
            rateHours
            rateExtra
            description
            unbillable
            created
            client {
                name
            }
            case {
                name
            }
        }
    }
}`

export const reportEmployeeWorkQueries =
gql`query ReportsEmployee($input: reportsInput) {
    reportsEmployee(input: $input) {
        error
        works {
            workTime
            extraHours
            rateType
            addCost
            addCostDesc
            rateValue
            rateHours
            rateExtra
            description
            unbillable
            created
            client {
                name
            }
            case {
                name
            }
        }
    }
}`

export class EmployeeInfo {
    firstName: string;
    lastName: string;
    email: string;
}

export class ClientInfo {
    id: string;
    name: string;
}

export class CaseInfo {
    id: string;
    name: string;
}

class WorkInfoClient {
    name: string;
}

class WorkInfoCase {
    name: string;
}

export class WorkInfo {
    workTime: number;
    extraHours: number;
    addCost: number;
    addCostDesc: string;
    rateType: string;
    rateValue: number;
    rateHours: number;
    rateExtra: number;
    description: string;
    unbillable: string;
    created: Date;
    client: WorkInfoClient;
    case: WorkInfoCase;
}

export class ReportEmployeeQueries {
    reportsEmployee: {
        error: string;
        employee: EmployeeInfo;
        clients: ClientInfo[];
        cases: CaseInfo[];
        works: WorkInfo[]
    }
}