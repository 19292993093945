import gql from 'graphql-tag';

export const ratesQueries =
gql`query RatesList($input: String) {
    rates(input: $input) {
        error
        clientName
        rates {
            id
            rateType
            rateValue
            rateHours
            rateExtra
            created
        }
    }
}`

export const ratesCreateEdit =
gql`mutation RatesCreateEdit($input: CreateEditRatesInput) {
    ratesCreateEdit(input: $input) {
        error
    }
}`

export const ratesDelete =
gql`mutation RatesDelete($input: String) {
    ratesDelete(input: $input) {
        error
    }
}`

export class RatesInfo {
    id: string;
    rateType: string;
    rateValue: number;
    rateHours: number;
    rateExtra: number;
    created: string;
    active: boolean;
}

export class ratesQuery {
    rates: {
        error: string;
        clientName: string;
        rates: RatesInfo[];
    }
}

export class ratesSaveMut {
    ratesCreateEdit: {
        error: string;
    }
}

export class ratesDeleteMut {
    ratesDelete: {
        error: string;
    }
}
