import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { Apollo } from 'apollo-angular';
import * as FileSaver from 'file-saver';

import { AlertService } from "../../services/alert.service";
import { FilesService } from '../../services/files.service';
import { I18n, CustomDatepickerI18n } from '../../services/calendar.service';
import { reportClientQueries, reportClientWorksQueries, ReportClientQueries, EmployeeInfo, ClientInfo, CaseInfo, WorkInfo } from '../../queries/reports.client.queries';
import { pdfClientQueries, PdfClientQueries } from '../../queries/pdf.queries';

const equals = (one: NgbDateStruct, two: NgbDateStruct) => one && two && two.year === one.year && two.month === one.month && two.day === one.day;
const after = (one: NgbDateStruct, two: NgbDateStruct) => !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day ? false : one.day > two.day : one.month > two.month : one.year > two.year;
const before = (one: NgbDateStruct, two: NgbDateStruct) => !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day ? false : one.day < two.day : one.month < two.month : one.year < two.year;

@Component({
    selector: 'clientReport',
    templateUrl: './clientReport.component.html',
    providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})

export class ClientReportComponent {

    editId: string;
    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    hoveredDate: NgbDateStruct;
    fromDate: NgbDateStruct;
    toDate: NgbDateStruct;
    model: any = {};
    dp: any = {};
    clientInfo: ClientInfo;
    emplList: EmployeeInfo[];
    caseList: CaseInfo[];
    workList: WorkInfo[];
    totalTable: any = {typeMonthly: false, fullMonth: false, billableTime: 0, unbillableTime: 0, totalTime: 0, billableExtra: 0, unbillableExtra: 0, totalExtra: 0, totalIncome: 0, monthlyNonBillable: 0, monthlyNonBillableExpences: 0, monthlyRateHours: 0, monthlyRatePrice: 0, monthlyRateExtra: 0, monthlyExtra: 0, monthlyExtraCost: 0, monthlyAdditional: 0, realizedHours: 0, monthlyTotal: 0};

  constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _ap: Apollo, private _ca: NgbCalendar, private _al: AlertService, private _fs: FilesService, private _i18n: I18n) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this._i18n.language = lang;
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        let thisMonth = this._ca.getToday();
        thisMonth.day = 1;
        this.fromDate = thisMonth;
        this.toDate = this._ca.getToday();
    }

    ngOnInit() {
        this._ar.params.subscribe(params => {
            this.editId = params.id;
            this.model = {employeeId: '', caseId: '', dateFrom: this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day, dateTo: this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day};
            this.loadingReport('all');
        });
    }
    
    loadingReport(type: string) {
        this.totalTable = {typeMonthly: false, fullMonth: false, billableTime: 0, unbillableTime: 0, totalTime: 0, billableExtra: 0, unbillableExtra: 0, totalExtra: 0, totalIncome: 0, monthlyNonBillable: 0, monthlyNonBillableExpences: 0, monthlyRateHours: 0, monthlyRatePrice: 0, monthlyRateExtra: 0, monthlyExtra: 0, monthlyExtraCost: 0, monthlyAdditional: 0, realizedHours: 0, monthlyTotal: 0};
        this.workList = null;
        let query;
        if (type == 'works')
            query = this._ap.watchQuery({ query: reportClientWorksQueries, variables: { input: { getCases: false, getEmployee: false, clientId: this.editId, ...this.model } } });
        else
            query = this._ap.watchQuery({ query: reportClientQueries, variables: { input: { getCases: true, getEmployee: true, clientId: this.editId, ...this.model } } });
        query.valueChanges.subscribe(result => {
            if (!(<ReportClientQueries>result.data).reportsClient) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                const report = (<ReportClientQueries>result.data).reportsClient;
                if ((report.error)&&(report.error == 'Not Authenticated')) {
                    this._al.error("SERVER.AUTH_ERROR");
                    this.workList = [];
                } else if ((report.error)&&(report.error == 'Permission denied')) {
                    this._al.error("SERVER.PERM_DENIED");
                    this.workList = [];
                } else {
                    if (type == 'all') {
                        this.clientInfo = report.client;
                        this.emplList = report.employee;
                        this.emplList.sort(function(a,b) { if(a.firstName < b.firstName) { return -1; } if(a.firstName > b.firstName) { return 1; } return 0; });
                    }
                    if ((type == 'all')||(type == 'cases')) {
                        this.caseList = report.cases;
                    }
                    this.workList = report.works;
                    this.countTotal();
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    changeEmployee() {
        this.model.caseId = '';
        this.loadingReport('works');
    }

    changeCases() {
        this.loadingReport('works');
    }

    toDates(period: string) {
        if (period == 'this') {
            let thisMonth = this._ca.getToday();
            thisMonth.day = 1;
            this.fromDate = thisMonth;
            this.toDate = this._ca.getPrev(this._ca.getNext(thisMonth, 'm', 1), 'd', 1);
        } else if (period == 'prev') {
            let thisMonth = this._ca.getToday();
            thisMonth.day = 1;
            this.fromDate = this._ca.getPrev(thisMonth, 'm', 1);
            this.toDate = this._ca.getPrev(thisMonth, 'd', 1);
        } else {
            this.fromDate = {day: null, month: null, year: null};
            this.toDate = {day: null, month: null, year: null};
        }
        this.model.dateFrom = this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day;
        this.model.dateTo = this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day;
        this.loadingReport('works');
    }

    onDateChange(date: NgbDateStruct) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
            this.toDate = date;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
        if ((this.fromDate != null)&&(this.toDate)) {
            this.model.dateFrom = this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day;
            this.model.dateTo = this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day;
            this.loadingReport('works');
        }
    }

    downloadPDF() {
        this._al.close();
        this._fs.show();
        let query = this._ap.watchQuery({ query: pdfClientQueries, variables: { input: { clientId: this.editId, ...this.model } } });
        query.valueChanges.subscribe(result => {
            this._fs.close();
            if (!(<PdfClientQueries>result.data).pdfReportsClient) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                const resp = (<PdfClientQueries>result.data).pdfReportsClient;
                const byteString = window.atob(resp.file);
                const arrayBuffer = new ArrayBuffer(byteString.length)
                let int8Array = new Uint8Array(arrayBuffer);
                for (let i = 0; i < byteString.length; i++) {
                    int8Array[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([int8Array], { type: 'application/pdf' });
                FileSaver.saveAs(blob, resp.fileName);
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    isHovered = (date: NgbDateStruct) => this.fromDate && !this.toDate && this.hoveredDate && after(date, this.fromDate) && before(date, this.hoveredDate);
    isInside = (date: NgbDateStruct) => after(date, this.fromDate) && before(date, this.toDate);
    isFrom = (date: NgbDateStruct) => equals(date, this.fromDate);
    isTo = (date: NgbDateStruct) => equals(date, this.toDate);

    private countTotal() {
        let sDate = new Date(this.fromDate.year+'-'+this.fromDate.month+'-'+this.fromDate.day);
        sDate.setHours(0, 0, 0, 0);
        let eDate = new Date(this.toDate.year+'-'+this.toDate.month+'-'+this.toDate.day);
        eDate.setDate(eDate.getDate() + 1);
        eDate.setMonth(eDate.getMonth() - 1);
        eDate.setHours(0, 0, 0, 0);
        if (sDate.getTime() === eDate.getTime())
            this.totalTable.fullMonth = true;
        this.totalTable.typeMonthly = true;
        for (let item of this.workList) {
            if (item.rateType != 'monthly')
                this.totalTable.typeMonthly = false;
            if (this.totalTable.fullMonth && this.totalTable.typeMonthly) {
                if (item.unbillable == 'Yes') {
                    this.totalTable.monthlyNonBillable += Number(item.workTime);
                    this.totalTable.monthlyNonBillableExpences += Number(item.addCost);
                } else {
                    this.totalTable.monthlyTotal = this.totalTable.monthlyTotal + Number(item.workTime);
                    this.totalTable.monthlyExtra = this.totalTable.monthlyExtra + Number(item.extraHours);
                    this.totalTable.monthlyExtraCost = this.totalTable.monthlyExtraCost + Number(item.extraHours) * Number(item.rateExtra);
                    this.totalTable.monthlyAdditional = this.totalTable.monthlyAdditional + Number(item.addCost);
                }
                this.totalTable.monthlyRateHours = Number(item.rateHours);
                this.totalTable.monthlyRatePrice = Number(item.rateValue);
                this.totalTable.monthlyRateExtra = Number(item.rateExtra);
            } else {
                if ((item.unbillable == 'Yes')||(item.rateType == 'nonbillable')) {
                    this.totalTable.unbillableTime += Number(item.workTime);
                    this.totalTable.unbillableExtra += Number(item.addCost);
                } else {
                    this.totalTable.billableTime += Number(item.workTime);
                    this.totalTable.billableExtra += Number(item.addCost);
                    if (item.rateType == 'hourly') {
                        this.totalTable.totalIncome = this.totalTable.totalIncome + Number(item.workTime) * Number(item.rateValue) + Number(item.addCost);
                    } else {
                        if (item.extraHours > 0) {
                            if (item.workTime - item.extraHours > 0) {
                                this.totalTable.totalIncome = this.totalTable.totalIncome + Number(item.workTime - item.extraHours) * (Number(item.rateValue)/Number(item.rateHours));
                            }
                            this.totalTable.totalIncome = this.totalTable.totalIncome + Number(item.extraHours) * Number(item.rateExtra) + Number(item.addCost);
                        } else {
                            this.totalTable.totalIncome = this.totalTable.totalIncome + Number(item.workTime) * (Number(item.rateValue)/Number(item.rateHours)) + Number(item.addCost);
                        }
                    }
                }
                this.totalTable.totalTime += Number(item.workTime);
                this.totalTable.totalExtra += Number(item.addCost);
            }
        }
    }

}
