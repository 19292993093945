import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { permissionsQueries, permissionsSave, permissionsQuery, permissionsSaveMut } from '../../queries/permissions.queries';

@Component({
    selector: 'permissions',
    templateUrl: './permissions.component.html'
})

export class PermissionsComponent {

    editId: string;
    addIp: string;
    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    model: any = {
        firstName: '',
        lastName: '',
        email: '',
        allowedIp: [],
        allowedClient: [],
        clientsList: [],
        permissions: {
            employee: { edit: false,report: false, permissions: false },
            jobs_other_employee: { edit: false, delete: false, view: false },
            clients: { edit: false, delete: false, report: false },
            dashboard: { clients: false, cases: false, employee: false }
        }
    };

    constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _ap: Apollo, private _al: AlertService) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this._ar.params.subscribe(params => {
            this.editId = params.id;
			let query = this._ap.watchQuery({ query: permissionsQueries, variables: { input: this.editId } });
			query.valueChanges.subscribe(result => {
                if (!(<permissionsQuery>result.data).permissions) {
                    this._al.error("SERVER.SERVER_ERROR");
                } else {
                    const resp = (<permissionsQuery>result.data).permissions;
                    if (resp.error == 'Permission denied') {
                        this._al.error("SERVER.PERM_DENIED");
                    } else if (resp.error == 'Not Authenticated') {
                        this._al.error("SERVER.AUTH_ERROR");
                    } else {
                        this.model = resp;
                        this.model.allowedClient = this.model.allowedClient ? this.model.allowedClient : []
                        this.model.allowedIp = this.model.allowedIp ? this.model.allowedIp : []
                        this.orderList();
                    }
                }
			}, err => {
                this._al.error("SERVER.SERVER_ERROR");
			});
        });
    }

    addIP() {
        if ((this.addIp != '')&&(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(this.addIp))) {
            this.model.allowedIp.push(this.addIp);
            this.addIp = '';
            this.savePermission();
        }
    }

    deleteIp(ip: string) {
        if (this.model.allowedIp.indexOf(ip) > -1) {
            this.model.allowedIp.splice(this.model.allowedIp.indexOf(ip), 1);
            this.savePermission();
        }
    }

    savePermission() {
        if (this.currentUser.id != this.editId) {
            this._al.close();
            let query = this._ap.mutate({ mutation: permissionsSave, variables: { 
                input: {
                    id: this.editId,
                    allowedIp: this.model.allowedIp,
                    allowedClient: this.model.allowedClient,
                    permissions: { 
                        employee: { 
                            edit: this.model.permissions.employee.edit,
                            report: this.model.permissions.employee.report,
                            permissions: this.model.permissions.employee.permissions
                        },
                        jobs_other_employee: {
                            edit: this.model.permissions.jobs_other_employee.edit,
                            delete: this.model.permissions.jobs_other_employee.delete,
                            view: this.model.permissions.jobs_other_employee.view
                        },
                        clients: {
                            edit: this.model.permissions.clients.edit,
                            delete: this.model.permissions.clients.delete,
                            report: this.model.permissions.clients.report
                        },
                        dashboard: {
                            clients: this.model.permissions.dashboard.clients,
                            cases: this.model.permissions.dashboard.cases,
                            employee: this.model.permissions.dashboard.employee
                        }
                    }
                }
            } });
            query.subscribe(result => { 
                const resp = (<permissionsSaveMut>result.data).permissionsSave;
                if (resp.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (resp.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else if (resp.error != 'none') {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    changeClients(id: string) {
        if (this.model.allowedClient.indexOf(id.toString()) > -1)
            this.model.allowedClient.splice(this.model.allowedClient.indexOf(id.toString()), 1);
        else
            this.model.allowedClient.push(id.toString());
        this.orderList();
        this.savePermission();
    }

    private orderList() {
        let list = this.model.allowedClient
        this.model.clientsList = this.model.clientsList.sort(function(a,b) { if(a.name < b.name) { return -1; } if(a.name > b.name) { return 1; } return 0; })
        this.model.clientsList = this.model.clientsList.sort(function(a) { if (list.indexOf(a.id) > -1) return -1; return 0; })
        this.model.clientsList = this.model.clientsList.sort(function(a, b) { if (list.indexOf(a.id) > -1) { if(a.name < b.name) { return -1; } if(a.name > b.name) { return 1; } return 0; } })
    }
  
}
