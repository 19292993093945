import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from "./guards/auth.guard";

import { LoginComponent } from './components/Guest/login.component';
import { ForgotComponent } from './components/Guest/forgot.component';
import { ResetComponent } from './components/Guest/reset.component';
import { DashboardComponent } from './components/Dashboard/dashboard.component';
import { ClientsComponent } from './components/Clients/clients.component';
import { RatesComponent } from './components/Rates/rates.component';
import { RatesLogComponent } from './components/Rates/ratesLog.component';
import { JobsComponent } from './components/Jobs/jobs.component';
import { CasesComponent } from './components/Cases/cases.component';
import { ColleaguesComponent } from './components/Colleagues/colleagues.component';
import { PermissionsComponent } from './components/Colleagues/permissions.component';
import { ClientReportComponent } from './components/Reports/clientReport.component';
import { ColleagueReportComponent } from './components/Reports/colleagueReport.component';
import { InvoiceComponent } from './components/Invoice/invoice.component';

const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'clients', component: ClientsComponent, canActivate: [AuthGuard] },
  { path: 'cases/:id', component: CasesComponent, canActivate: [AuthGuard] },
  { path: 'rates/:id', component: RatesComponent, canActivate: [AuthGuard] },
  { path: 'ratesLog/:id', component: RatesLogComponent, canActivate: [AuthGuard] },
  { path: 'colleagues', component: ColleaguesComponent, canActivate: [AuthGuard] },
  { path: 'colleagues/premissions/:id', component: PermissionsComponent, canActivate: [AuthGuard] },
  { path: 'jobs/:id', component: JobsComponent, canActivate: [AuthGuard] },
  { path: 'reports/clients/:id', component: ClientReportComponent, canActivate: [AuthGuard] },
  { path: 'reports/collegues/:id', component: ColleagueReportComponent, canActivate: [AuthGuard] },
  { path: 'invoice', component: InvoiceComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset/:id', component: ResetComponent },
  { path: '**', redirectTo: 'login' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
