import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { loginQueries, loginQuery } from '../../queries/autentificate.queries';

@Component({
  selector: 'login',
  templateUrl: './login.component.html'
})

export class LoginComponent {

  model: any = {email: '', password: '', office: '', offices: []};
  submitDisabled: boolean = false;
  showOffices: boolean = false;
  credentialError: boolean = false;

	constructor(private _ts: TranslateService, private _rtr: Router, private _al: AlertService, private _ap: Apollo) {
    localStorage.removeItem('bearer');
    localStorage.removeItem('permissions');
    localStorage.removeItem('currentUser');
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    _ts.addLangs([lang]);
    _ts.setDefaultLang(lang);
  }
  
  async submit() {
    this._al.close();
    this.submitDisabled = true;
    this.credentialError = false;
    let query = this._ap.watchQuery({ query: loginQueries, variables: { input: { email: this.model.email, password: this.model.password, office: this.model.office } } });
    query.valueChanges.subscribe(result => {
      this.submitDisabled = false;
      let res = (<loginQuery>result.data).login;
      if (res.error) {
        this.credentialError = true;
        this._al.error(res.error);
      } else if (res.offices) {
        this.showOffices = true;
        this.model.offices = res.offices;
      } else if (res.tocken) {
        res.currentUser.fullName = (res.currentUser.firstName ? res.currentUser.firstName : '')+(res.currentUser.lastName ? ' '+res.currentUser.lastName : '');
        localStorage.setItem('bearer', res.tocken);
        localStorage.setItem('permissions', res.permissions);
        localStorage.setItem('currentUser', JSON.stringify(res.currentUser));
        let returnURL = localStorage.hasOwnProperty('returnURL') ? <string>localStorage.getItem('returnURL') : '';
        localStorage.removeItem('returnURL');
        this._rtr.navigate([returnURL]);
      }
    }, err => {
      this.submitDisabled = false;
    });
  }

  langChange(lang) {
    localStorage.setItem('language', lang);
    this._ts.addLangs([lang]);
    this._ts.setDefaultLang(lang);
  }

}
