import gql from 'graphql-tag';

export const loginQueries =
gql`query Login($input: LoginInput) {
    login(input: $input) {
        tocken
        offices {
            id
            name
        }
        currentUser {
            id
            firstName
            lastName
        }
        permissions
        error
    }
}`

export const forgotQueries =
gql`query Forgot($input: ForgotInput) {
    forgot(input: $input) {
        message
        offices {
            id
            name
        }
        error
    }
}`

export const resetQueries =
gql`query Reset($input: ResetInput) {
    reset(input: $input) {
        message
        error
    }
}`

export class Office {
    id: string;
    name: string;
}

export class CurrentUser {
    id: String
    firstName: String
    lastName: String
    fullName: String
}

export class loginQuery {
    login: {
        tocken: string;
        offices: Office[];
        currentUser: CurrentUser;
        permissions: string;
        error: string;
    }
}

export class forgotQuery {
    forgot: {
        message: string;
        offices: Office[];
        error: string;
    }
}

export class resetQuery {
    reset: {
        message: string;
        error: string;
    }
}