import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { clientsQueries, clientDelete, clientCreateEdit, clientsQuery, clientCreateEditMut, clientDeleteMut, FullList, Client } from '../../queries/clients.queries';

@Component({
    selector: 'clients',
    templateUrl: './clients.component.html'
})

export class ClientsComponent {

    permissions: any = {};
    currentUser: any = {};
    model: any = {};
    modelError: any = {nameError: false, nipError: false};
    collapse: boolean = true;
    searchString: string = '';
    listHaveAll: boolean = false;
    showLoadingFull: boolean = false;
    clients: Client[] = null;
    fullList: FullList = {fullList: true, totalRecords: 0};
    buttonsDisabled: boolean = false;

	  constructor(private _ts: TranslateService, private _rtr: Router, private _al: AlertService, private _ap: Apollo) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        this.loadingClients();
    }

    loadingClients() {
        let query = this._ap.watchQuery({ query: clientsQueries, variables: {input: {all: this.listHaveAll, search: this.searchString}} });
        query.valueChanges.subscribe(result => {
            if (!(<clientsQuery>result.data).clients) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                let resp = (<clientsQuery>result.data).clients;
                if (resp.error) {
                    this._al.error("SERVER.AUTH_ERROR");
                    this.clients = [];
                } else {
                    this.clients = resp.clients;
                    this.fullList = resp.fullList;
                    this.showLoadingFull = false;
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    searchClient() {
        this.clients = null;
        this.listHaveAll = false;
        this.loadingClients();
    }

    showAllClients() {
        this.listHaveAll = true;
        this.fullList.fullList = true;
        this.showLoadingFull = true;
        this.loadingClients();
    }

    changeFormToCreate() {
        this._al.close();
        if (this.permissions.clients.edit) {
            this.modelError = {nameError: false, nipError: false};
            this.model = {
                form: "create", 
                id: null,
                name: '',
                address: '',
                nipNumber: '',
                description: '',
                invoiceInc: false,
                invoiceLang: 'pl',
                invoiceTerm: 0,
                invoiceVAT: '0'
            };
        }
    }

    changeFormToEdit(clientInfo: Client) {
        this._al.close();
        if (this.permissions.clients.edit) {
            this.modelError = {nameError: false, nipError: false};
            this.model = {
                form: "edit", 
                id: clientInfo.id,
                name: clientInfo.name,
                address: clientInfo.address,
                nipNumber: clientInfo.nipNumber,
                description: clientInfo.description,
                invoiceInc: clientInfo.invoiceInc == 'Yes' ? true : false,
                invoiceLang: clientInfo.invoiceLang ? clientInfo.invoiceLang : 'pl',
                invoiceTerm: Number(clientInfo.invoiceTerm),
                invoiceVAT: Number(clientInfo.invoiceVAT).toString()
            };
            window.scrollTo(0, 1);
        }
    }

    changeFormToCalcel() {
        this._al.close();
        this.modelError = {nameError: false, nipError: false};
        this.model = {};
    }

    saveForm() {
        this._al.close();
        this.buttonsDisabled = true
        this.modelError = {nameError: false, nipError: false};
        if (this.model.name == '') {
            this.modelError.nameError = true;
        } else if ((this.model.invoiceInc)&&(this.model.nipNumber == '')) {
            this.modelError.nipError = true;
        } else {
            let query = this._ap.mutate({ mutation: clientCreateEdit, variables: { input: { "form": this.model.form, "id": this.model.id, "name": this.model.name, "nipNumber": this.model.nipNumber, "address": this.model.address, "description": this.model.description, "invoiceInc": this.model.invoiceInc ? 'Yes' : 'No', "invoiceLang": this.model.invoiceLang, "invoiceTerm": Number(this.model.invoiceTerm), "invoiceVAT": Number(this.model.invoiceVAT) } } });
            query.subscribe(result => {
                this.buttonsDisabled = false
                let res = (<clientCreateEditMut>result.data).clientCreateEdit
                if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Name required') {
                    this.modelError.nameError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'NIP Number required') {
                    this.modelError.nipError = true;
                    window.scrollTo(0, 1);
                } else if (res.client) {
                    let newClient = res.client
                    if (this.model.form == 'create') {
                        this._al.success("CLIENTS.MESSAGES.CREATED");
                    } else {
                        let index = this.clients.findIndex(x => x.id === newClient.id);
                        if (index !== -1)
                            this.clients.splice(index, 1);
                        this._al.success("CLIENTS.MESSAGES.SAVED");
                    }
                    this.clients.unshift(newClient);
                    this.modelError = {nameError: false};
                    this.model = {};
    
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }
  
    deleteClient(clientInfo: Client) {
        this._al.close();
        if (this.permissions.clients.delete) {
            let query = this._ap.mutate({ mutation: clientDelete, variables: { input: clientInfo.id.toString() } });
            query.subscribe(result => {
                let res = (<clientDeleteMut>result.data).clientDelete
                if (res.error == 'none') {
                    let index = this.clients.indexOf(clientInfo);
                    if (index !== -1)
                        this.clients.splice(index, 1);
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }
  
}
