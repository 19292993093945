import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { TranslateService } from '@ngx-translate/core';

import { AlertService } from "../../services/alert.service";

@Component({
  selector: 'alert',
  templateUrl: 'alert.component.html'
})

export class AlertComponent implements OnDestroy {
  private subscription: Subscription;
  message: any;

  constructor(private _ts: TranslateService, private alertService: AlertService) {
    let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    _ts.addLangs([lang]);
    _ts.setDefaultLang(lang);
    this.subscription = alertService.getMessage().subscribe(message => {
      if (message && message.type === undefined)
        message = undefined;
      this.message = message;
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
