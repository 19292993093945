import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import * as FileSaver from 'file-saver';

import { AlertService } from "../../services/alert.service";
import { FilesService } from '../../services/files.service';
import { I18n, CustomDatepickerI18n } from '../../services/calendar.service';
import { clientsQueries, clientsQuery, Client } from '../../queries/invoice.queries';
import { pdfInvoiceQueries, PdfInvoice } from '../../queries/pdf.queries';

@Component({
    selector: 'invoice',
    templateUrl: './invoice.component.html',
    providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})

export class InvoiceComponent {

    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    di: NgbDateStruct;
    ds: NgbDateStruct;
    clients: Client[] = null;
    model: any = {
        type: 'invoice',
        number: '001',
        month: ('0' + ((new Date()).getMonth() + 1)).slice(-2),
        year: (new Date()).getFullYear(),
        note: '',
        clientId: '',
        name: '',
        address: '',
        nip: '',
        lang: 'pl',
        amount: 0,
        vat_type: "23",
        paymentMethod: 'money',
        paymentTerm: 0,
        description: '',
        splitPayment: false
    };
    modelError: any = {number: false, month: false, year: false, name: false, nip: false, amount: false, paymentTerm: false, description: false};

  constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _al: AlertService, private _fs: FilesService, private _ap: Apollo, private _i18n: I18n) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this._i18n.language = lang;
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }

    ngOnInit() {
        const now = new Date();
        this.di = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        this.ds = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        let query = this._ap.watchQuery({ query: clientsQueries, variables: {input: {all: true, search: ''}} });
        query.valueChanges.subscribe(result => {
            if (!(<clientsQuery>result.data).clients) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                let resp = (<clientsQuery>result.data).clients;
                if (resp.error) {
                    this._al.error("SERVER.AUTH_ERROR");
                } else {
                    this.clients = resp.clients;
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    changeClient() {
        if (this.model.clientId != '') {
            let index = this.clients.findIndex(x => x.id === this.model.clientId);
            this.model.name = this.clients[index].name;
            this.model.address = this.clients[index].address ? this.clients[index].address : '';
            this.model.nip = this.clients[index].nipNumber ? this.clients[index].nipNumber : '';
            this.model.lang = this.clients[index].invoiceLang;
            this.model.vat_type = this.clients[index].invoiceVAT ? (this.clients[index].invoiceVAT).toString() : '';
        } else {
            this.model.name = '';
            this.model.address = '';
            this.model.nip = '';
            this.model.lang = 'pl';
            this.model.vat_type = '23';
        }
    }

    downloadPdf() {
        this.modelError = {number: false, month: false, year: false, name: false, nip: false, amount: false, paymentTerm: false, description: false};
        if (Number(this.model.number) < 1) {
            this.modelError.number = true;
        } else if (Number(this.model.month) < 1) {
            this.modelError.month = true;
        } else if (Number(this.model.year) < 1) {
            this.modelError.year = true;
        } else if (this.model.name == '') {
            this.modelError.name = true;
        } else if (this.model.nip == '') {
            this.modelError.nip = true;
        } else if (Number(this.model.amount) < 1) {
            this.modelError.amount = true;
        } else if (Number(this.model.paymentTerm) < 0) {
            this.modelError.paymentTerm = true;
        } else if (this.model.description == '') {
            this.modelError.description = true;
        } else {
            this._fs.show();
            const issueDate = new Date(this.di.year+'-'+this.di.month+'-'+this.di.day+' 00:00:00');
            const saleDate = new Date(this.ds.year+'-'+this.ds.month+'-'+this.ds.day+' 00:00:00');
            let query = this._ap.watchQuery({ query: pdfInvoiceQueries, variables: { input:{
                invoiceType: this.model.type,
                invoiceNumber: this.model.number + '/' + this.model.month + '/' + this.model.year,
                invoiceNote: this.model.note,
                name: this.model.name,
                address: this.model.address,
                nip: this.model.nip,
                language: this.model.lang,
                amount: Number(this.model.amount),
                vatType: Number(this.model.vat_type),
                paymentMethod: this.model.paymentMethod,
                issueDate: issueDate,
                saleDate: saleDate,
                peymentTerm: Number(this.model.paymentTerm),
                description: this.model.description,
                splitPayment: this.model.splitPayment
            } } });
            query.valueChanges.subscribe(result => {
                this._fs.close();
                if ((<PdfInvoice>result.data).pdfInvoice) {
                    const resp = (<PdfInvoice>result.data).pdfInvoice;
                    const byteString = window.atob(resp.file);
                    const arrayBuffer = new ArrayBuffer(byteString.length)
                    let int8Array = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < byteString.length; i++) {
                        int8Array[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([int8Array], { type: 'application/pdf' });
                    FileSaver.saveAs(blob, resp.fileName);
                }
            }, err => { });
        }
    }

}
