import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { interval } from 'rxjs';
import * as FileSaver from 'file-saver';

import { FilesService } from '../../services/files.service';

import { pdfArchiveQueries, pdfInvoicesQueries, pdfCheckQueries, PdfArchiveQueries, PdfInvoices, PdfCheckQueries } from '../../queries/pdf.queries';

@Component({
  selector: 'navButtons',
  templateUrl: 'nav.component.html'
})

export class NavButtonsComponent {

    private checkFiles;
    startNumber: number = 1;

    constructor(private _ts: TranslateService, private _ap: Apollo, private _fs: FilesService) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
    }

    ngOnInit() {
        let data = JSON.parse(localStorage.getItem('currentUser'));
        if ((data.fileReports)||(data.fileInvoices)) {
            this.checkCreated();
        }
    }
    ngOnDestroy() {
        if (this.checkFiles)
            this.checkFiles.unsubscribe()
    }

    report_past() {
        this.reportPDF('past');
    }

    report_cur() {
        this.reportPDF('current');
    }

    invoice_all() {
        this._fs.show();
        let data = JSON.parse(localStorage.getItem('currentUser'));
        if (!data.fileInvoices) {
            let query = this._ap.watchQuery({ query: pdfInvoicesQueries, variables: { input: this.startNumber.toString() } });
            query.valueChanges.subscribe(result => {
                this._fs.close();
                if ((<PdfInvoices>result.data).pdfInvoices) {
                    const resp = (<PdfInvoices>result.data).pdfInvoices;
                    if (resp.message == "Ok") {
                        data.fileInvoices = true;
                        localStorage.setItem('currentUser', JSON.stringify(data));
                        this.checkCreated();
                    }
                }
            }, err => { });
        }
    }

    private reportPDF(type: string) {
        this._fs.show();
        let data = JSON.parse(localStorage.getItem('currentUser'));
        if (!data.fileReports) {
            let query = this._ap.watchQuery({ query: pdfArchiveQueries, variables: { input: type } });
            query.valueChanges.subscribe(result => {
                this._fs.close();
                if ((<PdfArchiveQueries>result.data).pdfArchive) {
                    const resp = (<PdfArchiveQueries>result.data).pdfArchive;
                    if (resp.message == "Ok") {
                        data.fileReports = true;
                        localStorage.setItem('currentUser', JSON.stringify(data));
                        this.checkCreated();
                    }
                }
            }, err => { });
        }
    }

    private checkCreated() {
        const source = interval(10000);
        this.checkFiles = source.subscribe((val) => {
            let query = this._ap.watchQuery({ query: pdfCheckQueries, variables: { } });
            query.valueChanges.subscribe(result => {
                if ((<PdfCheckQueries>result.data).archiveCheck) {
                    const resp = (<PdfCheckQueries>result.data).archiveCheck;
                    if ((resp.message == "OkReports")||(resp.message == "OkInvoices")) {
                        let data = JSON.parse(localStorage.getItem('currentUser'));
                        if (resp.message == "OkReports")
                            delete data.fileReports;
                        if (resp.message == "OkInvoices")
                            delete data.fileInvoices;
                        localStorage.setItem('currentUser', JSON.stringify(data));
                        this.checkFiles.unsubscribe()
                        const byteString = window.atob(resp.file);
                        const arrayBuffer = new ArrayBuffer(byteString.length)
                        let int8Array = new Uint8Array(arrayBuffer);
                        for (let i = 0; i < byteString.length; i++) {
                            int8Array[i] = byteString.charCodeAt(i);
                        }
                        const blob = new Blob([int8Array], { type: 'application/pdf' });
                        FileSaver.saveAs(blob, resp.fileName);
                    } else if (resp.message == "Printring") {
                    } else {
                        let data = JSON.parse(localStorage.getItem('currentUser'));
                        delete data.fileReports;
                        delete data.fileInvoices;
                        localStorage.setItem('currentUser', JSON.stringify(data));
                        this.checkFiles.unsubscribe()
                    }
                } else {
                    let data = JSON.parse(localStorage.getItem('currentUser'));
                    delete data.fileReports;
                    delete data.fileInvoices;
                    localStorage.setItem('currentUser', JSON.stringify(data));
                    this.checkFiles.unsubscribe()
                }
            }, err => { });
        })
    }

}
