import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from "../../services/alert.service";
import { I18n, CustomDatepickerI18n } from '../../services/calendar.service';
import { ratesQueries, ratesQuery, ratesCreateEdit, ratesDelete, ratesSaveMut, ratesDeleteMut, RatesInfo } from "../../queries/rates.queries";

@Component({
    selector: 'rates',
    templateUrl: './rates.component.html',
    providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})

export class RatesComponent {

    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    model: any = {};
    dp: NgbDateStruct;
    dateNow: Date = new Date();
    editId: string = '';
    editClientName: string;
    ratesList: RatesInfo[] = null;
    modelError: any = {rateValueError: false, rateHoursError: false, rateExtraError: false, dateError: false};
    buttonsDisabled: boolean = false;

  constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _al: AlertService, private _ap: Apollo, private _i18n: I18n) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this._i18n.language = lang;
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  
    ngOnInit() {
        this._ar.params.subscribe(params => {
            this.editId = params.id;
            this.loadingRates();
        });
    }

    loadingRates(): void {
        this.ratesList = null;
        let query = this._ap.watchQuery({ query: ratesQueries, variables: { input: this.editId } });
        query.valueChanges.subscribe(result => {
            if (!(<ratesQuery>result.data).rates) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                const resp = (<ratesQuery>result.data).rates;
                if ((resp.error)&&(resp.error == 'Not Authenticated')) {
                    this._al.error("SERVER.AUTH_ERROR");
                    this.ratesList = [];
                } else if ((resp.error)&&(resp.error == 'Permission denied')) {
                    this._al.error("SERVER.PERM_DENIED");
                    this.ratesList = [];
                } else {
                    this.ratesList = resp.rates;
                    this.editClientName = resp.clientName;
                    this.markActiveRate();
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    changeFormToCreate(): void {
        if (this.permissions.clients.edit) {
            this._al.close();
            this.modelError = {rateValueError: false, rateHoursError: false, rateExtraError: false, dateError: false};
            let now = new Date();
            this.model = {
                form: "create", 
                id: null,
                rateType: 'nonbillable',
                rateValue: 0,
                rateHours: 0,
                rateExtra: 0
            };
            this.dp = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        }
    }

    changeFormToEdit(ratesInfo: RatesInfo): void {
        if (this.permissions.clients.edit) {
            this._al.close();
            this.modelError = {rateValueError: false, rateHoursError: false, rateExtraError: false, dateError: false};
            let now = new Date(ratesInfo.created);
            this.model = {
                form: "edit", 
                id: ratesInfo.id,
                rateType: ratesInfo.rateType,
                rateValue: Number(ratesInfo.rateValue),
                rateHours: Number(ratesInfo.rateHours),
                rateExtra: Number(ratesInfo.rateExtra)
            };
            this.dp = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
            window.scrollTo(0, 1);
        }
    }

    changeFormToCalcel(): void {
        this._al.close();
        this.modelError = {rateValueError: false, rateHoursError: false, rateExtraError: false, dateError: false};
        this.model = {};
        this.dp = null;
    }

    markActiveRate() {
        this.ratesList.sort((a,b) => (new Date(b.created)).getTime() - (new Date(a.created)).getTime());
        let first = true;
        this.ratesList.forEach(function (item) {
            if (new Date(item.created) > new Date())
                item.active = false;
            else {
                item.active = first;
                first = false;
            }
        });
    }

    deleteRates(ratesInfo: RatesInfo): void {
        this._al.close();
        if (this.permissions.clients.delete) {
            let query = this._ap.mutate({ mutation: ratesDelete, variables: { input: ratesInfo.id.toString() } });
            query.subscribe(result => {
                let res = (<ratesDeleteMut>result.data).ratesDelete
                if (res.error == 'none') {
                    this.loadingRates();
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    saveForm(): void {
        this._al.close();
        this.buttonsDisabled = true
        this.modelError = {rateValueError: false, rateHoursError: false, rateExtraError: false, dateError: false};
        const date = new Date(this.dp.year+'-'+this.dp.month+'-'+this.dp.day);
        if ((this.model.rateType != 'nonbillable')&&(!(Number(this.model.rateValue) > 0))) {
            this.modelError.rateValueError = true;
        } else if ((this.model.rateType == 'monthly')&&(!(Number(this.model.rateHours) > 0))) {
            this.modelError.rateHoursError = true;
        } else if ((this.model.rateType == 'monthly')&&(!(Number(this.model.rateExtra) > 0))) {
            this.modelError.rateExtraError = true;
        } else if (date.toString() == 'Invalid Date') {
            this.modelError.dateError = true;
        } else {
            if (this.model.rateType == 'nonbillable') {
                this.model.rateValue = 0;
                this.model.rateHours = 0;
                this.model.rateExtra = 0;
            }
            let query = this._ap.mutate({ mutation: ratesCreateEdit, variables: { input: { 
                "form": this.model.form, 
                "id": this.model.id, 
                "clientId": this.editId, 
                "rateType": this.model.rateType,
                "rateValue": Number(this.model.rateValue),
                "rateHours": Number(this.model.rateHours),
                "rateExtra": Number(this.model.rateExtra),
                "created": date.getFullYear()+'-'+('0' + (date.getMonth() + 1)).slice(-2)+"-"+('0' + date.getDate()).slice(-2)
            } } });
            query.subscribe(result => {
                this.buttonsDisabled = false
                let res = (<ratesSaveMut>result.data).ratesCreateEdit
                if (res.error == 'none') {
                    if (this.model.form == 'create')
                        this._al.success("RATES.MESSAGES.CREATED");
                    else
                        this._al.success("RATES.MESSAGES.SAVED");
                    this.loadingRates();
                    this.modelError = {rateValueError: false, rateHoursError: false, rateExtraError: false, dateError: false};
                    this.model = {};
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Date required') {
                    this.modelError.dateError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Payment required') {
                    this.modelError.rateValueError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Included hours required') {
                    this.modelError.rateHoursError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Extra hourly payment required') {
                    this.modelError.rateExtraError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Client not found') {
                    this._al.error('RATES.ERROR.CLIENT_NOTFOUND');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }
}
