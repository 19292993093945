import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';

import { AlertService } from "../../services/alert.service";
import { I18n, CustomDatepickerI18n } from '../../services/calendar.service';
import { jobsQueries, jobsQuery, jobsDelete, jobsCreateEdit, jobsDeleteMut, jobsCreateEditMut, JobsInfo, CaseInfo, FullList } from '../../queries/jobs.queries';

@Component({
    selector: 'jobs',
    templateUrl: './jobs.component.html',
    providers: [I18n, { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n }]
})

export class JobsComponent {

    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    model: any = {};
    dp: NgbDateStruct;
    modelError = {caseError: false, dateError: false};
    editId: string = '';
    listHaveAll: boolean = false;
    listHaveCase: string = '';
    editClientName: string;
    worksList: JobsInfo[] = null;
    casesList: CaseInfo[] = null;
    fullList: FullList = {fullList: true, totalRecords: 0};
    showLoadingFull: boolean = false;
    buttonsDisabled: boolean = false;

  constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _al: AlertService, private _ap: Apollo, private _i18n: I18n) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this._i18n.language = lang;
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  
    ngOnInit() {
        this._ar.params.subscribe(params => {
            this.editId = params.id;
            this.loadingWorks();
        });
    }

    loadingWorks() {
        let query = this._ap.watchQuery({ query: jobsQueries, variables: { input: { "all": this.listHaveAll, "caseId": this.listHaveCase, "clientId": this.editId } } });
        query.valueChanges.subscribe(result => {
            if (!(<jobsQuery>result.data).works) {
                this._al.error("SERVER.SERVER_ERROR");
            } else {
                let resp = (<jobsQuery>result.data).works;
                if ((resp.error)&&(resp.error == 'Not Authenticated')) {
                    this._al.error("SERVER.AUTH_ERROR");
                    this.worksList = [];
                } else if ((resp.error)&&(resp.error == 'Permission denied')) {
                    this._al.error("SERVER.PERM_DENIED");
                    this.worksList = [];
                } else {
                    this.worksList = resp.works;
                    this.casesList = resp.cases;
                    this.fullList = resp.fullList;
                    this.editClientName = resp.clientName;
                    this.showLoadingFull = false;
                }
            }
        }, err => {
            this._al.error("SERVER.SERVER_ERROR");
        });
    }

    changeCase() {
        this.listHaveAll = false;
        this.loadingWorks();
    }

    showAllJobs() {
        this.listHaveAll = true;
        this.fullList.fullList = true;
        this.showLoadingFull = true;
        this.loadingWorks();
    }

    changeFormToCreate() {
        this._al.close();
        this.modelError = {caseError: false, dateError: false};
        let now = new Date();
        this.model = {
            form: "create", 
            id: null,
            caseId: '',
            description: '',
            workTime: 0,
            inputMinutes: 0,
            addCost: 0,
            addCostDesc: '',
            created_hour: now.getHours(),
            created_minute: (now.getMinutes() < 10 ? '0'+now.getMinutes() : now.getMinutes()),
            created_second: (now.getSeconds() < 10 ? '0'+now.getSeconds() : now.getSeconds()),
            unbillable: false
        };
        this.dp = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    }

    changeFormToEdit(jobsInfo: JobsInfo) {
        this._al.close();
        this.modelError = {caseError: false, dateError: false};
        let now = new Date(jobsInfo.created);
        this.model = {
            form: "edit", 
            id: jobsInfo.id,
            caseId: jobsInfo.case.id,
            description: jobsInfo.description,
            workTime: jobsInfo.workTime,
            inputMinutes: Number(jobsInfo.workTime) * 60,
            addCost: jobsInfo.addCost,
            addCostDesc: jobsInfo.addCostDesc,
            created_hour: now.getHours(),
            created_minute: (now.getMinutes() < 10 ? '0'+now.getMinutes() : now.getMinutes()),
            created_second: (now.getSeconds() < 10 ? '0'+now.getSeconds() : now.getSeconds()),
            unbillable: jobsInfo.unbillable == 'Yes'
        };
        this.dp = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
        window.scrollTo(0, 1);
    }

    changeFormToCalcel(): void {
        this._al.close();
        this.model = {};
        this.dp = null;
    }

    deleteJobs(jobInfo: JobsInfo) {
        this._al.close();
        if ((this.permissions.jobs_other_employee.delete)||(this.currentUser.id == jobInfo.employee.id)) {
            let query = this._ap.mutate({ mutation: jobsDelete, variables: { input: jobInfo.id.toString() } });
            query.subscribe(result => {
                let res = (<jobsDeleteMut>result.data).workDelete
                if (res.error == 'none') {
                    let index = this.worksList.indexOf(jobInfo);
                    if (index !== -1)
                        this.worksList.splice(index, 1);
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    saveForm() {
        this._al.close();
        this.buttonsDisabled = true
        this.modelError = { caseError: false, dateError: false };
        let date = new Date(this.dp.year + '-' + this.dp.month.toString().padStart(2, '0') + '-' + this.dp.day.toString().padStart(2, '0') + 'T' + this.model.created_hour.toString().padStart(2, '0') + ':' + this.model.created_minute.toString().padStart(2, '0') + ':' + this.model.created_second.toString().padStart(2, '0') + 'Z');
        const _userOffset = date.getTimezoneOffset() * 60 * 1000
        date = new Date(date.getTime() + _userOffset)
        if (this.model.caseId == '') {
          this.modelError.caseError = true;
          this.buttonsDisabled = false
        } else if (date.toString() == 'Invalid Date') {
          this.modelError.dateError = true;
          this.buttonsDisabled = false
        } else {
            let query = this._ap.mutate({ mutation: jobsCreateEdit, variables: { input: { 
                "form": this.model.form, 
                "id": this.model.id, 
                "caseId": this.model.caseId,
                "clientId": this.editId,
                "workTime": Number(this.model.workTime),
                "addCost": Number(this.model.addCost),
                "addCostDesc": this.model.addCostDesc,
                "description": this.model.description,
                "unbillable": this.model.unbillable ? 'Yes' : 'No',
                "created": date
            } } });
            query.subscribe(result => {
                this.buttonsDisabled = false
                let res = (<jobsCreateEditMut>result.data).workCreateEdit
                if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Case required') {
                    this.modelError.caseError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Date required') {
                    this.modelError.dateError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Client not found') {
                    this._al.error('CASES.ERROR.CLIENT_NOTFOUND');
                    window.scrollTo(0, 1);
                } else if (res.work) {
                    if (this.model.form == 'create') {
                        this._al.success("JOBS.MESSAGES.CREATED");
                    } else {
                        let index = this.worksList.findIndex(x => x.id === res.work.id);
                        if (index !== -1)
                            this.worksList.splice(index, 1);
                        this._al.success("JOBS.MESSAGES.SAVED");
                    }
                    this.worksList.unshift(res.work);
                    this.modelError = {caseError: false, dateError: false};
                    this.model = {};
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    timeChange() {
        this.model.inputMinutes = Number(this.model.workTime) * 60;
    }
}
