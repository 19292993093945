import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { casesQueries, casesQuery, caseCreateEdit, caseDelete, caseStatus, caseDeleteMut, caseStatusMut, caseCaseEditMut, CaseInfo } from '../../queries/cases.queries';

@Component({
    selector: 'cases',
    templateUrl: './cases.component.html'
})  
export class CasesComponent {
  
    permissions: any = {};
    currentUser: any = {};
    model: any = {};
    collapse: boolean = true;
    editId: string = '';
    editClientName: string;
    casesList: CaseInfo[] = null;
    modelError: any = {nameError: false};
    buttonsDisabled: boolean = false;

    constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _al: AlertService, private _ap: Apollo) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
    
    ngOnInit() {
        this._ar.params.subscribe(params => {
            this.editId = params.id;
            let query = this._ap.watchQuery({ query: casesQueries, variables: { input: this.editId } });
            query.valueChanges.subscribe(result => {
                if (!(<casesQuery>result.data).cases) {
                    this._al.error("SERVER.SERVER_ERROR");
                } else {
                    const resp = (<casesQuery>result.data).cases;
                    if ((resp.error)&&(resp.error == 'Not Authenticated')) {
                        this._al.error("SERVER.AUTH_ERROR");
                        this.casesList = [];
                    } else if ((resp.error)&&(resp.error == 'Permission denied')) {
                        this._al.error("SERVER.PERM_DENIED");
                        this.casesList = [];
                    } else {
                        this.casesList = resp.cases;
                        this.editClientName = resp.clientName;
                        this.casesList.sort(function(a,b) { if(a.name < b.name) { return -1; } if(a.name > b.name) { return 1; } return 0; });
                    }
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        });
    }

    changeFormToCreate(): void {
        if (this.permissions.clients.edit) {
            this._al.close();
            this.modelError = {nameError: false};
            this.model = {
                form: "create", 
                id: null,
                name: '',
                description: ''
            };
        }
    }

    changeFormToEdit(caseInfo: CaseInfo): void {
        if (this.permissions.clients.edit) {
            this._al.close();
            this.modelError = {nameError: false};
            this.model = {
                form: "edit", 
                id: caseInfo.id,
                name: caseInfo.name,
                description: caseInfo.description
            };
            window.scrollTo(0, 1);
        }
    }

    changeFormToCalcel(): void {
        this._al.close();
        this.modelError = {nameError: false};
        this.model = {};
    }
    
    deleteCase(caseInfo: CaseInfo): void {
        this._al.close();
        if (this.permissions.clients.delete) {
            let query = this._ap.mutate({ mutation: caseDelete, variables: { input: caseInfo.id.toString() } });
            query.subscribe(result => {
                let res = (<caseDeleteMut>result.data).caseDelete
                if (res.error == 'none') {
                    let index = this.casesList.indexOf(caseInfo);
                    if (index !== -1)
                        this.casesList.splice(index, 1);
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    changeStatus(caseInfo: CaseInfo): void {
        this._al.close();
        if (this.permissions.clients.edit) {
            let query = this._ap.mutate({ mutation: caseStatus, variables: { input: caseInfo.id.toString() } });
            query.subscribe(result => {
                let res = (<caseStatusMut>result.data).caseStatus
                if (res.error == 'none') {
                    caseInfo.statusOC = caseInfo.statusOC == 'No' ? 'Yes' : 'No';
                } else if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

    saveForm(): void {
        this._al.close();
        this.buttonsDisabled = true
        this.modelError = {nameError: false};
        if (this.model.name == '') {
            this.modelError.nameError = true;
        } else {
            let query = this._ap.mutate({ mutation: caseCreateEdit, variables: { input: { "form": this.model.form, "id": this.model.id, "clientID": this.editId, "name": this.model.name, "description": this.model.description } } });
            query.subscribe(result => {
                this.buttonsDisabled = false
                let res = (<caseCaseEditMut>result.data).caseCreateEdit
                if (res.error == 'Not Authenticated') {
                    this._al.error('SERVER.AUTH_ERROR');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Permission denied') {
                    this._al.error('SERVER.PERM_DENIED');
                    window.scrollTo(0, 1);
                } else if (res.error == 'Name required') {
                    this.modelError.nameError = true;
                    window.scrollTo(0, 1);
                } else if (res.error == 'Client not found') {
                    this._al.error('CASES.ERROR.CLIENT_NOTFOUND');
                    window.scrollTo(0, 1);
                } else if (res.case) {
                    let newCaseInfi = res.case;
                    if (this.model.form == 'create') {
                        this._al.success("CASES.MESSAGES.CREATED");
                    }
                    else {
                        let index = this.casesList.findIndex(x => x.id === newCaseInfi.id);
                        if (index !== -1)
                            this.casesList.splice(index, 1);
                        this._al.success("CASES.MESSAGES.SAVED");
                    }
                    this.casesList.unshift(newCaseInfi);
                    this.casesList.sort(function(a,b) { if(a.name < b.name) { return -1; } if(a.name > b.name) { return 1; } return 0; });
                    this.modelError = {nameError: false};
                    this.model = {};
                } else {
                    this._al.error("SERVER.SERVER_ERROR");
                    window.scrollTo(0, 1);
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        }
    }

}
