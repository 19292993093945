import gql from 'graphql-tag';

export const clientsQueries =
gql`query ClientsList($input: ClientsListInput) {
    clients(input: $input) {
        error
        clients {
            id
            name
            nipNumber
            address
            invoiceInc
            invoiceLang
            invoiceTerm
            invoiceVAT
        }
    }
}`

export class Client {
    id: string;
    name: string;
    nipNumber: string;
    address: string;
    invoiceInc: boolean;
    invoiceLang: string;
    invoiceTerm: number;
    invoiceVAT: number;
}

export class clientsQuery {
    clients: {
        error: string;
        clients: Client[];
    }
}