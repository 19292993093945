import gql from 'graphql-tag';

export const clientsQueries =
gql`query ClientsList($input: ClientsListInput) {
    clients(input: $input) {
        error
        clients {
            id
            name
            nipNumber
            address
            description
            invoiceInc
            invoiceLang
            invoiceTerm
            invoiceVAT
            jobsCount
            jobsCountOwn
            casesOpCount
            casesClCount
            rate {
                rateType
                rateValue
                rateHours
                rateExtra
            }
        }
        fullList {
            fullList
            totalRecords
        }
    }
}`

export const clientCreateEdit =
gql`mutation ClientCreateEdit($input: CreateEditClientInput) {
    clientCreateEdit(input: $input) {
        error
        client {
            id
            name
            nipNumber
            address
            description
            invoiceInc
            invoiceLang
            invoiceTerm
            invoiceVAT
            jobsCount
            jobsCountOwn
            casesOpCount
            casesClCount
            rate {
                rateType
                rateValue
                rateHours
                rateExtra
            }
        }
    }
}`

export const clientDelete =
gql`mutation ClientDelete($input: String) {
    clientDelete(input: $input) {
        error
    }
}`

export class Client {
    id: string;
    name: string;
    nipNumber: string;
    address: string;
    description: string;
    invoiceInc: string;
    invoiceLang: string;
    invoiceTerm: number;
    invoiceVAT: number;
    jobsCount: number;
    jobsCountOwn: number;
    casesOpCount: number;
    casesClCount: number;
    rate: ClientRates;
}

export class ClientRates {
    rateType: string;
    rateValue: number;
    rateHours: number;
    rateExtra: number;
}

export class FullList {
    fullList: boolean;
    totalRecords: number;
}

export class clientsQuery {
    clients: {
        error: string;
        clients: Client[];
        fullList: FullList;
    }
}

export class clientCreateEditMut {
    clientCreateEdit: {
        error: string;
        client: Client;
    }
}

export class clientDeleteMut {
    clientDelete: {
        error: string;
    }
}
