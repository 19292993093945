import gql from 'graphql-tag';

export const pdfClientQueries =
gql`query PdfReportsClient($input: pdfClientInput) {
    pdfReportsClient(input: $input) {
        error
        fileName
        file
    }
}`

export const pdfEmployeeQueries =
gql`query PdfReportsEmployee($input: pdfEmployeeInput) {
    pdfReportsEmployee(input: $input) {
        error
        fileName
        file
    }
}`

export const pdfArchiveQueries =
gql`query PdfArchive($input: String) {
    pdfArchive(input: $input) {
        message
    }
}`

export const pdfInvoicesQueries =
gql`query PdfInvoices($input: String) {
    pdfInvoices(input: $input) {
        message
    }
}`

export const pdfInvoiceQueries =
gql`query PdfInvoice($input: pdfInvoiceInput) {
    pdfInvoice(input: $input) {
        error
        fileName
        file
    }
}`

export const pdfCheckQueries =
gql`query ArchiveCheck {
    archiveCheck {
        message
        fileName
        file
    }
}`

export class PdfClientQueries {
    pdfReportsClient: {
        error: string;
        fileName: string;
        file: string;
    }
}

export class PdfEmployeeQueries {
    pdfReportsEmployee: {
        error: string;
        fileName: string;
        file: string;
    }
}

export class PdfCheckQueries {
    archiveCheck: {
        message: string;
        fileName: string;
        file: string;
    }
}

export class PdfArchiveQueries {
    pdfArchive: {
        message: string;
    }
}

export class PdfInvoices {
    pdfInvoices: {
        message: string;
    }
}

export class PdfInvoice {
    pdfInvoice: {
        error: string;
        fileName: string;
        file: string;
    }
}