import { Injectable } from '@angular/core';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

const I18N_VALUES = {
    en: {
        weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
        monthsShot: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    },
    pl: {
        weekdays: ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'],
        monthsShot: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
        months: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień']
    }
};

@Injectable()
    export class I18n {
      language = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
    }

@Injectable()
    export class CustomDatepickerI18n extends NgbDatepickerI18n {
        constructor(private _i18n: I18n) {
            super();
        }
        getWeekdayShortName(weekday: number): string {
            return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
        }
        getMonthShortName(month: number): string {
            return I18N_VALUES[this._i18n.language].monthsShot[month - 1];
        }
        getMonthFullName(month: number): string {
            return I18N_VALUES[this._i18n.language].months[month - 1];
        }
        getDayAriaLabel(date: NgbDateStruct): string {
            return `${date.day}-${date.month}-${date.year}`
        }
    }
