import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';

import { AlertService } from "../../services/alert.service";
import { ratesLogQueries, ratesLogQuery, RatesLogInfo, RatesEmploeeInfo } from "../../queries/ratesLog.queries";

@Component({
    selector: 'ratesLog',
    templateUrl: './ratesLog.component.html'
})

export class RatesLogComponent {

    permissions: any = {};
    currentUser: any = {};
    collapse: boolean = true;
    editId: string = '';
    editClientName: string;
    ratesList: RatesLogInfo[] = null;

  	constructor(private _ts: TranslateService, private _ar: ActivatedRoute, private _rtr: Router, private _al: AlertService, private _ap: Apollo) {
        let lang = localStorage.getItem('language') ? localStorage.getItem('language') : 'en';
        _ts.addLangs([lang]);
        _ts.setDefaultLang(lang);
        this.permissions = JSON.parse(localStorage.getItem('permissions'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    }
  
    ngOnInit() {
        this._ar.params.subscribe(params => {
            this.editId = params.id;
            this.ratesList = null;
            let query = this._ap.watchQuery({ query: ratesLogQueries, variables: { input: this.editId } });
            query.valueChanges.subscribe(result => {
                if (!(<ratesLogQuery>result.data).ratesLog) {
                    this._al.error("SERVER.SERVER_ERROR");
                } else {
                    const resp = (<ratesLogQuery>result.data).ratesLog;
                    if ((resp.error)&&(resp.error == 'Not Authenticated')) {
                        this._al.error("SERVER.AUTH_ERROR");
                        this.ratesList = [];
                    } else if ((resp.error)&&(resp.error == 'Permission denied')) {
                        this._al.error("SERVER.PERM_DENIED");
                        this.ratesList = [];
                    } else {
                        this.ratesList = resp.rates;
                        this.editClientName = resp.clientName;
                        this.ratesList.sort((a,b) => - (new Date(b.created)).getTime() - (new Date(a.created)).getTime());
                    }
                }
            }, err => {
                this._al.error("SERVER.SERVER_ERROR");
            });
        });
    }

}
